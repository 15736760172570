import axios from 'axios';

import { URL_HOST } from '@/constants/environment';

interface ScrappingUrlRequestOptions {
  method: 'POST';
  url: string;
  headers: { 'Content-Type': 'application/json' };
  data: {
    business_text: string;
  };
}

export const summarizeText = async (text: string) => {
  const options: ScrappingUrlRequestOptions = {
    method: 'POST',
    url: `${URL_HOST}/summarize`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      business_text: text,
    },
  };

  const response = await axios.request(options);

  // TODO: remove this log after demo
  // eslint-disable-next-line no-console
  console.log(response.data);
  return response.data as string;
};
