/* eslint-disable max-len */
import axios from 'axios';

import { URL_HOST } from '@/constants/environment';
import { Message } from '@/components/Widget/utils/MessageProccesor';

export const mock_1 = [
  {
    internal_name: 'saludo',
    label: 'Saludo inicial',
    parent: null,
    response: '¡Bienvenido a Cliengo! ¿En qué podemos ayudarte?',
    response_options: [
      'Consultas generales',
      'Planes y precios',
      'Integraciones',
      'Programa para agencias',
      'Bot de WhatsApp',
    ],
  },
  {
    internal_name: 'consultas_generales',
    label: 'Consultas generales',
    parent: 'Saludo inicial',
    response: '¿En qué podemos ayudarte?',
    response_options: [
      'Funcionalidades del chatbot',
      'Visibilidad de conversaciones',
      'Mejora de experiencia del cliente',
      'Trazabilidad de conversaciones',
      'Mensajes salientes de WhatsApp',
    ],
  },
  {
    internal_name: 'funcionalidades_chatbot',
    label: 'Funcionalidades del chatbot',
    parent: 'Consultas generales',
    response: '¿Qué funcionalidades del chatbot te interesan?',
    response_options: [
      'Automatización de respuestas',
      'Conversaciones a medida',
      'Filtrado de conversaciones',
      'Ventas impulsadas',
    ],
  },
  {
    internal_name: 'visibilidad_conversaciones',
    label: 'Visibilidad de conversaciones',
    parent: 'Consultas generales',
    response:
      '¿Quieres tener visibilidad de todas las conversaciones generadas por tus agentes?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'mejora_experiencia_cliente',
    label: 'Mejora de experiencia del cliente',
    parent: 'Consultas generales',
    response: '¿Quieres mejorar la experiencia de tus clientes?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'trazabilidad_conversaciones',
    label: 'Trazabilidad de conversaciones',
    parent: 'Consultas generales',
    response: '¿Quieres tener trazabilidad en las conversaciones generadas?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'mensajes_salientes_whatsapp',
    label: 'Mensajes salientes de WhatsApp',
    parent: 'Consultas generales',
    response: '¿Quieres enviar mensajes salientes de WhatsApp HSM?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'planes_precios',
    label: 'Planes y precios',
    parent: 'Saludo inicial',
    response: '¿Quieres conocer nuestros planes y precios?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'integraciones',
    label: 'Integraciones',
    parent: 'Saludo inicial',
    response: '¿Quieres conocer nuestras integraciones?',
    response_options: [
      'Google Ads',
      'Facebook Ads',
      'Instagram Ads',
      'Google Analytics',
    ],
  },
  {
    internal_name: 'programa_agencias',
    label: 'Programa para agencias',
    parent: 'Saludo inicial',
    response: '¿Quieres conocer nuestro programa para agencias?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'bot_whatsapp',
    label: 'Bot de WhatsApp',
    parent: 'Saludo inicial',
    response: '¿Quieres conocer nuestro bot de WhatsApp?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'nombre',
    label: 'Nombre',
    parent: null,
    response: '¿Cuál es tu nombre?',
    response_options: [],
  },
  {
    internal_name: 'email',
    label: 'E-mail',
    parent: null,
    response: '¿Podrías indicarme tu correo electrónico?',
    response_options: [],
  },
  {
    internal_name: 'telefono',
    label: 'Teléfono',
    parent: null,
    response: 'Por favor, indícame un número de contacto.',
    response_options: [],
  },
  {
    internal_name: 'despedida',
    label: 'Despedida',
    parent: null,
    response:
      '¡Gracias por contactarte con Cliengo! Nos pondremos en contacto contigo a la brevedad.',
    response_options: [],
  },
];

export const mock_2 = [
  {
    internal_name: 'salute',
    label: 'Saludo inicial',
    parent: '',
    response:
      '¡Bienvenido a la Universidad Andina del Cusco! ¿En qué podemos ayudarte hoy?',
    response_options: ['Admisión', 'Movilidad', 'Bolsa Laboral'],
  },
  {
    internal_name: 'admission',
    label: 'Admisión',
    parent: 'Saludo inicial',
    response: '¿Te gustaría postular para el ciclo ordinario 2023-II?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'mobility',
    label: 'Movilidad',
    parent: 'Saludo inicial',
    response:
      '¿Te gustaría conocer más sobre nuestros programas de movilidad académica?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'job_opportunities',
    label: 'Bolsa Laboral',
    parent: 'Saludo inicial',
    response: '¿Te gustaría conocer nuestras convocatorias oficiales?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'name',
    label: 'Nombre',
    parent: '',
    response:
      'Para poder brindarte una atención personalizada, ¿nos podrías indicar tu nombre?',
    response_options: [],
  },
  {
    internal_name: 'email',
    label: 'E-mail',
    parent: '',
    response:
      '¿Podrías indicarnos tu correo electrónico para poder contactarte?',
    response_options: [],
  },
  {
    internal_name: 'phone',
    label: 'Teléfono',
    parent: '',
    response:
      'Por favor, indícanos un número de contacto para poder comunicarnos contigo.',
    response_options: [],
  },
  {
    internal_name: 'final_salutation',
    label: 'Despedida',
    parent: '',
    response:
      '¡Gracias por comunicarte con nosotros! Nos pondremos en contacto contigo a la brevedad.',
    response_options: [],
  },
];

export const mock_3 = [
  {
    internal_name: 'saludo',
    label: 'Saludo inicial',
    parent: null,
    response: '¡Bienvenido a Realtor.com! ¿En qué podemos ayudarte hoy?',
    response_options: [
      'Comprar una casa',
      'Vender una propiedad',
      'Alquilar una propiedad',
      'Hipotecas',
      'Agentes inmobiliarios',
      'Otro',
    ],
  },
  {
    internal_name: 'compra',
    label: 'Comprar una casa',
    parent: 'Comprar una casa',
    response: '¿Estás buscando una casa, apartamento o terreno?',
    response_options: ['Casa', 'Apartamento', 'Terreno'],
  },
  {
    internal_name: 'venta',
    label: 'Vender una propiedad',
    parent: 'Vender una propiedad',
    response: '¿Qué tipo de propiedad quieres vender?',
    response_options: ['Casa', 'Apartamento', 'Terreno', 'Propiedad nueva'],
  },
  {
    internal_name: 'alquiler',
    label: 'Alquilar una propiedad',
    parent: 'Alquilar una propiedad',
    response: '¿Estás buscando un apartamento o una casa para alquilar?',
    response_options: ['Apartamento', 'Casa'],
  },
  {
    internal_name: 'hipoteca',
    label: 'Hipotecas',
    parent: 'Hipotecas',
    response:
      '¿Estás buscando información sobre tasas de interés o calculadoras de hipotecas?',
    response_options: ['Tasas de interés', 'Calculadoras de hipotecas'],
  },
  {
    internal_name: 'agente',
    label: 'Agentes inmobiliarios',
    parent: 'Agentes inmobiliarios',
    response: '¿Estás buscando un agente inmobiliario en Miami?',
    response_options: ['Sí', 'No'],
  },
  {
    internal_name: 'otro',
    label: 'Otro',
    parent: 'Otro',
    response: 'Por favor, describe en qué podemos ayudarte.',
    response_options: [],
  },
  {
    internal_name: 'nombre',
    label: 'Nombre',
    parent: null,
    response:
      'Para darte una atención más personalizada, ¿Me podrías indicar tu nombre?',
    response_options: [],
  },
  {
    internal_name: 'email',
    label: 'E-mail',
    parent: null,
    response: '¿Podrías indicarme tu email?',
    response_options: [],
  },
  {
    internal_name: 'telefono',
    label: 'Teléfono',
    parent: null,
    response: 'Por favor, indícame un número de contacto.',
    response_options: [],
  },
  {
    internal_name: 'despedida',
    label: 'Despedida',
    parent: null,
    response:
      '¡Muchas gracias por comunicarte con Realtor.com! Nos pondremos en contacto contigo a la brevedad.',
    response_options: [],
  },
];

interface GenerateNewTemplateRequestOptions {
  method: 'POST';
  url: string;
  headers: { 'Content-Type': 'application/json' };
  data: {
    website_id: string;
    jwt: string;
    business_data: string;
  };
}

export const generateNewTemplate = async (text: string) => {
  const options: GenerateNewTemplateRequestOptions = {
    method: 'POST',
    url: `${URL_HOST}/enhancer`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      website_id: '644c3d75a9752e003272f406',
      jwt: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwcml2aWxlZ2VzIjpbImFkbWluIiwiYWxsLXdlYnNpdGVzIl0sInYiOjEsImNvbXBhbnkiOiI2MjFjYzdjYWY5MDI0MzAwMmFlN2VkZjkiLCJ3ZWJzaXRlcyI6W10sInVzZXIiOiI2MzNkZTg4YWZhOWRjYTAwMjY3Y2Y2YWYiLCJ0aW1lc3RhbXAiOjE2ODMwNzUwNjE4NzN9.t77squkbOeagCtHUMDLar2J6EOwB0dTf6HlPpG4-lSs',
      business_data: text,
    },
  };

  const response = await axios.request(options);

  // const response = {
  //   data: {
  //     enhanced_question_list: mock_1,
  //   },
  // };

  // TODO: remove this log after demo
  // eslint-disable-next-line no-console
  console.log(response.data);
  return (response.data as Record<string, Message[]>).enhanced_question_list;
};

/*
NOTAS:

For our purposes, a "conversation script" is list of messages that
a chatbot will send to a user, each message having a label
(for a human to understand what the message is about on a configuration UI),
a response (the actual text that the chatbot will send), a list of quick replies
(for the user to select from a list of options), and a parent (to indicate
that the message is a response to another message). The parent is optional

---
interface QuickReplyButton {
  label: string;
  value: string;
}

interface ChatMessage {
  id: string;
  label: string;
  parent?: string;
  ancestors?: string[];
  response: string;
  quickReplies?: QuickReplyButton[];
}

ok, so, lets try some things to validate that this structure is usefull.

Imagine this conversation flow:
{
 id: salute,
 label: 'Greeting',
 response: 'Hello! welcome to Cliengo, how can I help you today',
 quickReplies: [
  { label: 'Services info', value: 'service_info' },
  { label: 'I have a problem', value: 'i_have_a_problem' }],
 fallback: '
},
{
 id: 'service_info',
 label: 'Services Info',
 parent: 'salute',
 response: 'We have many services for your business, on wich one are you interested?',
 quickReplies: [
  {label: 'Chatbot', value: 'chatbot'},
  {label: 'CRM', value: 'crm'}
 ],
},
{
  id: 'chatbot',
  label: 'Chatbot Info',
  parent: 'service_info',
  response: 'Our chatbot is the best in the market, it can do many things, like... Do you want to know more?',
  quickReplies: [
    {label: 'Yes', value: '!client_data'},
    {label: 'No', value: '!aditional_question'}
  ],
},
{
  id: 'client_data',
  label: 'Client Data',
  response: 'In order to give you a more tailored experience, can you give me your name?',
},
{
  id: 'aditional_question',
  label: 'Aditional Question',
  response: 'Do you have any other questions?'
}
---
*/
