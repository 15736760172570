export const StatusInformation = {
  errorUrl:
    'Es posible que la url que nos pasaste este\n bloqueada y no nos permite analizarla',
  errorInfo:
    'Hubo un error en el análisis\n de la información, intentalo de nuevo',
  scrapping: 'Estamos analizando la url\n para crear tu conversación',
  summarize:
    'Estamos resumiendo el texto obtenido\n para crear tu conversación',
  enhance: 'Estamos generando la mejor\n conversación para tu negocio',
  minutes: 'Esto puede demorar unos minutos'
};
