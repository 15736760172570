export const EnhancerPicture = () => {
  return (
    <svg
      width="298"
      height="228"
      viewBox="0 0 298 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.052 228C226.501 228 284.421 225.295 284.421 221.959C284.421 218.623 226.501 215.918 155.052 215.918C83.6032 215.918 25.6826 218.623 25.6826 221.959C25.6826 225.295 83.6032 228 155.052 228Z"
        fill="#E6E6E6"
      />
      <path
        d="M207.859 56.3419V179.398C207.856 181.461 207.032 183.438 205.568 184.896C204.104 186.355 202.119 187.175 200.048 187.178H32.2402C31.1784 187.178 30.16 186.757 29.4092 186.01C28.6584 185.262 28.2366 184.247 28.2366 183.19V54.8488C28.2366 53.7912 28.6584 52.7768 29.4092 52.0289C30.16 51.2811 31.1784 50.8609 32.2402 50.8609H206.162C206.612 50.8609 207.044 51.0389 207.362 51.3557C207.68 51.6726 207.859 52.1023 207.859 52.5504V56.3419Z"
        fill="#E1FFF6"
      />
      <path d="M201.646 66.0565H57.1399V119.54H201.646V66.0565Z" fill="white" />
      <path
        d="M201.646 124.667H57.1399V178.151H201.646V124.667Z"
        fill="white"
      />
      <path
        d="M52.4657 56.5385V187.178H32.2404C31.1942 187.178 30.1909 186.764 29.4512 186.027C28.7115 185.29 28.2959 184.291 28.2959 183.249V56.5385H52.4657Z"
        fill="#E6E6E6"
      />
      <path
        d="M207.859 51.47V56.6956H28.2268V52.6684C28.2268 52.1438 28.3307 51.6244 28.5325 51.14C28.7343 50.6555 29.0301 50.2154 29.4029 49.845C29.7758 49.4745 30.2183 49.181 30.7052 48.9812C31.192 48.7813 31.7137 48.6791 32.2403 48.6804H206.163C206.612 48.6804 207.044 48.8584 207.362 49.1753C207.68 49.4921 207.859 49.9218 207.859 50.3699V51.47Z"
        fill="#66D0C2"
      />
      <path
        d="M36.5891 54.4361C37.5748 54.4361 38.374 53.6401 38.374 52.6582C38.374 51.6763 37.5748 50.8803 36.5891 50.8803C35.6033 50.8803 34.8042 51.6763 34.8042 52.6582C34.8042 53.6401 35.6033 54.4361 36.5891 54.4361Z"
        fill="white"
      />
      <path
        d="M42.7031 54.4361C43.6889 54.4361 44.488 53.6401 44.488 52.6582C44.488 51.6763 43.6889 50.8803 42.7031 50.8803C41.7173 50.8803 40.9182 51.6763 40.9182 52.6582C40.9182 53.6401 41.7173 54.4361 42.7031 54.4361Z"
        fill="white"
      />
      <path
        d="M48.8269 54.4361C49.8126 54.4361 50.6118 53.6401 50.6118 52.6582C50.6118 51.6763 49.8126 50.8803 48.8269 50.8803C47.8411 50.8803 47.042 51.6763 47.042 52.6582C47.042 53.6401 47.8411 54.4361 48.8269 54.4361Z"
        fill="white"
      />
      <path d="M48.7183 89.5225H36.0664V90.77H48.7183V89.5225Z" fill="white" />
      <path
        d="M48.7183 85.8391H33.1277V87.4205H48.7183V85.8391Z"
        fill="white"
      />
      <path
        d="M48.7183 104.276H36.0664V105.523H48.7183V104.276Z"
        fill="white"
      />
      <path
        d="M48.7183 100.582H33.1277V102.164H48.7183V100.582Z"
        fill="white"
      />
      <path
        d="M48.7183 119.019H36.0664V120.267H48.7183V119.019Z"
        fill="white"
      />
      <path
        d="M48.7183 115.336H33.1277V116.917H48.7183V115.336Z"
        fill="white"
      />
      <path
        d="M48.7183 133.763H36.0664V135.011H48.7183V133.763Z"
        fill="white"
      />
      <path d="M48.7183 130.08H33.1277V131.661H48.7183V130.08Z" fill="white" />
      <path
        d="M117.885 136.494V160.932C117.884 162.628 117.229 164.258 116.054 165.485C114.878 166.713 113.274 167.442 111.574 167.523C111.469 167.533 111.363 167.533 111.258 167.523H7.58729C6.1522 167.522 4.7562 167.057 3.60919 166.198C2.46219 165.339 1.62616 164.132 1.22681 162.759C1.06075 162.164 0.977791 161.549 0.980279 160.932V136.494C0.980262 134.745 1.67541 133.068 2.91362 131.83C4.15183 130.591 5.83229 129.891 7.58729 129.883H111.258C113.016 129.883 114.701 130.578 115.944 131.816C117.187 133.054 117.885 134.733 117.885 136.484V136.494Z"
        fill="#66D0C2"
      />
      <path
        opacity="0.33"
        d="M117.885 138.959V160.932C117.884 162.628 117.229 164.259 116.054 165.486C114.878 166.713 113.274 167.442 111.574 167.523C111.469 167.533 111.363 167.533 111.258 167.523H7.58729C6.1522 167.522 4.7562 167.057 3.60919 166.198C2.46219 165.339 1.62616 164.132 1.22681 162.759C1.06075 162.164 0.977791 161.55 0.980279 160.932V138.959H117.885Z"
        fill="white"
      />
      <path
        d="M5.07275 133.685L7.86348 136.464"
        stroke="white"
        strokeWidth="2.56108"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.03096 133.685L5.24023 136.464"
        stroke="white"
        strokeWidth="2.56108"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M76.9707 147.279H11.8867V150.225H76.9707V147.279Z"
        fill="white"
      />
      <path
        d="M76.9707 154.597H11.8867V157.543H76.9707V154.597Z"
        fill="white"
      />
      <path
        d="M278.672 211.793H248.733L238.004 218.777C237.811 218.893 237.59 218.957 237.363 218.962C237.137 218.967 236.914 218.914 236.714 218.807C236.515 218.7 236.348 218.543 236.228 218.351C236.108 218.16 236.041 217.941 236.032 217.716L235.914 208.974L235.45 208.669C232.958 206.998 230.916 204.743 229.503 202.102C228.09 199.461 227.348 196.515 227.344 193.523V193.523C227.357 188.681 229.294 184.042 232.731 180.618C236.168 177.195 240.826 175.266 245.686 175.253H278.672C283.533 175.266 288.19 177.195 291.627 180.618C295.064 184.042 297.001 188.681 297.014 193.523V193.523C297.001 198.364 295.064 203.004 291.627 206.427C288.19 209.851 283.533 211.78 278.672 211.793Z"
        fill="white"
        stroke="#515151"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M273.564 184.751H239.829C238.277 184.751 237.018 186.005 237.018 187.551C237.018 189.097 238.277 190.35 239.829 190.35H273.564C275.116 190.35 276.374 189.097 276.374 187.551C276.374 186.005 275.116 184.751 273.564 184.751Z"
        fill="#A8EDDE"
      />
      <path
        d="M284.806 195.154H239.829C238.277 195.154 237.018 196.407 237.018 197.953C237.018 199.499 238.277 200.753 239.829 200.753H284.806C286.358 200.753 287.616 199.499 287.616 197.953C287.616 196.407 286.358 195.154 284.806 195.154Z"
        fill="#A8EDDE"
      />
      <path
        d="M213.529 179.791H243.458L254.197 186.785C254.39 186.901 254.611 186.965 254.838 186.97C255.064 186.976 255.287 186.922 255.487 186.815C255.686 186.708 255.853 186.551 255.973 186.36C256.093 186.168 256.16 185.949 256.169 185.724L256.287 176.982L256.751 176.677C259.243 175.006 261.285 172.751 262.698 170.11C264.111 167.47 264.852 164.524 264.857 161.531V161.531C264.843 156.69 262.907 152.05 259.47 148.627C256.033 145.203 251.375 143.274 246.515 143.261H213.529C208.668 143.274 204.011 145.203 200.574 148.627C197.137 152.05 195.2 156.69 195.187 161.531V161.531C195.203 166.371 197.14 171.008 200.577 174.429C204.014 177.851 208.67 179.778 213.529 179.791Z"
        fill="white"
        stroke="#515151"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M252.491 152.848H207.514C205.961 152.848 204.703 154.101 204.703 155.647C204.703 157.193 205.961 158.447 207.514 158.447H252.491C254.043 158.447 255.301 157.193 255.301 155.647C255.301 154.101 254.043 152.848 252.491 152.848Z"
        fill="#9378F8"
      />
      <path
        d="M236.427 163.25H207.514C205.961 163.25 204.703 164.504 204.703 166.05C204.703 167.596 205.961 168.849 207.514 168.849H236.427C237.979 168.849 239.237 167.596 239.237 166.05C239.237 164.504 237.979 163.25 236.427 163.25Z"
        fill="#9378F8"
      />
      <path
        d="M185.247 52.2754H184.034V19.1637C184.034 14.0812 182.007 9.20681 178.399 5.61292C174.791 2.01903 169.897 0 164.795 0H94.3661C89.2652 0.00260422 84.3742 2.02278 80.7683 5.61639C77.1623 9.21 75.1367 14.0829 75.1367 19.1637V19.1637V200.811C75.1354 203.327 75.6319 205.819 76.5976 208.144C77.5634 210.469 78.9796 212.582 80.7654 214.361C82.5511 216.141 84.6714 217.553 87.005 218.516C89.3387 219.479 91.84 219.975 94.3661 219.975H164.785C167.312 219.976 169.814 219.482 172.149 218.519C174.484 217.556 176.605 216.145 178.392 214.365C180.179 212.585 181.596 210.472 182.562 208.146C183.529 205.821 184.026 203.328 184.024 200.811V200.811V75.8396H185.237L185.247 52.2754Z"
        fill="#515151"
      />
      <path
        d="M165.574 4.98976H156.383C156.805 6.02192 156.966 7.14159 156.851 8.25018C156.736 9.35877 156.35 10.4223 155.725 11.347C155.101 12.2718 154.258 13.0294 153.27 13.5531C152.283 14.0769 151.181 14.3508 150.062 14.3506H109.71C108.59 14.354 107.487 14.082 106.498 13.5589C105.509 13.0358 104.665 12.2776 104.041 11.3517C103.416 10.4257 103.031 9.36062 102.919 8.25085C102.807 7.14107 102.972 6.02092 103.399 4.98976H94.8097C90.9991 4.98976 87.3446 6.49756 84.6501 9.18147C81.9556 11.8654 80.4419 15.5055 80.4419 19.3012V19.3012V200.674C80.4419 204.469 81.9556 208.109 84.6501 210.793C87.3446 213.477 90.9991 214.985 94.8097 214.985H165.574C169.384 214.985 173.039 213.477 175.733 210.793C178.428 208.109 179.942 204.469 179.942 200.674V200.674V19.3012C179.942 15.5055 178.428 11.8654 175.733 9.18147C173.039 6.49756 169.384 4.98976 165.574 4.98976V4.98976Z"
        fill="white"
      />
      <path
        d="M104.424 35.8228C104.424 30.6963 100.252 26.5405 95.1055 26.5405C89.9588 26.5405 85.7866 30.6963 85.7866 35.8228C85.7866 40.9492 89.9588 45.105 95.1055 45.105C100.252 45.105 104.424 40.9492 104.424 35.8228Z"
        fill="#9378F8"
      />
      <path
        d="M106.406 33.1803H164.913C168.453 33.1803 171.934 33.1803 171.934 37.8165V55.0943C171.934 59.76 168.059 61.1352 164.509 61.1352H120.38C116.83 61.1352 112.954 60.3395 112.954 55.6738V37.0111L106.406 33.1803Z"
        fill="#E6E6E6"
      />
      <path
        d="M146.246 37.9936H121.159V40.3117H146.246V37.9936Z"
        fill="#9378F8"
      />
      <path
        d="M164.874 46.0186H121.159V48.3367H164.874V46.0186Z"
        fill="white"
      />
      <path
        d="M164.834 54.0436H121.159V56.3617H164.834V54.0436Z"
        fill="white"
      />
      <path
        d="M104.424 124.225C104.424 119.099 100.252 114.943 95.1055 114.943C89.9588 114.943 85.7866 119.099 85.7866 124.225C85.7866 129.352 89.9588 133.508 95.1055 133.508C100.252 133.508 104.424 129.352 104.424 124.225Z"
        fill="#9378F8"
      />
      <path
        d="M106.406 121.583H164.913C168.453 121.583 171.934 121.583 171.934 126.219V146.149C171.934 150.815 168.059 152.19 164.509 152.19H120.38C116.83 152.19 112.954 151.394 112.954 146.729V125.384L106.406 121.583Z"
        fill="#E6E6E6"
      />
      <path
        d="M146.246 126.396H121.159V128.714H146.246V126.396Z"
        fill="#9378F8"
      />
      <path
        d="M164.874 134.421H121.159V136.739H164.874V134.421Z"
        fill="white"
      />
      <path
        d="M164.834 142.446H121.159V144.764H164.834V142.446Z"
        fill="white"
      />
      <path
        d="M171.934 80.0241C171.934 74.8976 167.762 70.7418 162.615 70.7418C157.469 70.7418 153.296 74.8976 153.296 80.0241C153.296 85.1505 157.469 89.3063 162.615 89.3063C167.762 89.3063 171.934 85.1505 171.934 80.0241Z"
        fill="#66D0C2"
      />
      <path
        d="M151.314 77.3816H92.8078C89.2676 77.3816 85.7866 77.3816 85.7866 82.0178V101.948C85.7866 106.613 89.6621 107.989 93.2121 107.989H137.341C140.891 107.989 144.767 107.193 144.767 102.527V81.1829L151.314 77.3816Z"
        fill="#E6E6E6"
      />
      <path
        d="M136.562 82.1949H111.475V84.513H136.562V82.1949Z"
        fill="#9378F8"
      />
      <path d="M136.552 90.2199H92.8374V92.538H136.552V90.2199Z" fill="white" />
      <path
        d="M136.562 98.2449H92.8867V100.563H136.562V98.2449Z"
        fill="white"
      />
      <path
        d="M171.619 167.591C171.619 162.465 167.446 158.309 162.3 158.309C157.153 158.309 152.981 162.465 152.981 167.591C152.981 172.718 157.153 176.874 162.3 176.874C167.446 176.874 171.619 172.718 171.619 167.591Z"
        fill="#9378F8"
      />
      <path
        d="M149.293 165.941C149.391 165.881 149.467 165.792 149.509 165.685C149.551 165.579 149.558 165.462 149.528 165.351C149.498 165.241 149.433 165.143 149.343 165.072C149.252 165.001 149.141 164.962 149.027 164.959H92.5021C88.9521 164.959 85.481 164.959 85.481 169.605V189.525C85.481 194.191 89.3564 195.566 92.9064 195.566H137.035C140.576 195.566 144.451 194.77 144.451 190.105V168.751L149.293 165.941Z"
        stroke="#9378F8"
        strokeMiterlimit="10"
      />
      <path
        d="M136.256 169.772H111.169V172.09H136.256V169.772Z"
        fill="#9378F8"
      />
      <path
        d="M136.246 177.797H92.5317V180.115H136.246V177.797Z"
        fill="#E6E6E6"
      />
      <path
        d="M136.247 185.822H92.5713V188.141H136.247V185.822Z"
        fill="#E6E6E6"
      />
      <path
        d="M170.79 174.016L165.169 170.087C164.983 169.484 164.851 168.866 164.775 168.24C165.304 167.763 165.682 167.141 165.86 166.452H165.919C166.412 166.845 167.349 162.671 165.771 161.885C165.771 161.885 164.588 159.331 162.32 159.92C160.051 160.51 158.779 161.394 158.681 162.278C158.616 163.013 158.616 163.753 158.681 164.488C158.715 164.214 158.809 163.951 158.955 163.717C159.102 163.483 159.297 163.284 159.529 163.132C158.894 163.856 158.573 164.801 158.636 165.76C158.699 166.719 159.14 167.614 159.864 168.25C160.043 168.407 160.238 168.545 160.446 168.663C160.278 169.242 160.042 170.008 159.854 170.558L158.138 171.413L154.391 173.77C153.478 174.972 152.808 176.34 152.419 177.797L152.301 178.308H172.279C171.648 176.354 170.79 174.016 170.79 174.016Z"
        fill="#E6E6E6"
      />
      <path
        d="M171.619 167.591C171.619 162.465 167.446 158.309 162.3 158.309C157.153 158.309 152.981 162.465 152.981 167.591C152.981 172.718 157.153 176.874 162.3 176.874C167.446 176.874 171.619 172.718 171.619 167.591Z"
        stroke="#9378F8"
        strokeWidth="2.56108"
        strokeMiterlimit="10"
      />
      <path
        d="M60.7293 220.486C60.7293 220.486 56.1734 217.539 54.7534 212.863C53.3334 208.188 55.5226 203.277 55.5423 203.227L55.6409 203.002L55.848 203.139C55.8973 203.139 60.4138 206.086 61.8239 210.761C63.2341 215.437 61.0547 220.348 61.035 220.397L60.9364 220.623L60.7293 220.486Z"
        fill="#F2F2F2"
      />
      <path
        d="M59.3292 206.774C57.6034 213.65 61.5479 220.574 61.5479 220.574C61.5479 220.574 67.4647 216.115 69.1411 209.21C70.8175 202.305 67.3562 195.458 67.3562 195.458C67.3562 195.458 61.0549 199.859 59.3292 206.774Z"
        fill="#E6E6E6"
      />
      <path
        d="M67.1096 196.018C63.3244 203.311 61.2985 211.383 61.1929 219.592"
        stroke="#3F3D56"
        strokeWidth="0.640271"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M56.2622 203.876C55.8805 209.398 57.2611 214.899 60.2067 219.592"
        stroke="#3F3D56"
        strokeWidth="0.640271"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
