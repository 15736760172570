import { Spinner } from 'cliengo-ds';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { ScrappingPicture } from './assets/scrappingPicture';
import { SummarizePicture } from './assets/summarizePicture';
import { EnhancerPicture } from './assets/enhancerPicture';
import { ErrorInfoPicture } from './assets/errorInfoPicture';
import { ErrorUrlPicture } from './assets/errorUrlPicture';

import { StatusInformation } from '@/constants/statusInformation';
import { generateNewTemplate } from '@/requests/generateNewTemplate';
import {
  initMessageQueue,
  templateMessages,
} from '@/components/Widget/utils/MessageProccesor';
import { scrapUrl, businessInfo } from '@/signals';
import { scrappingUrl } from '@/requests/scrappingUrl';
import { summarizeText } from '@/requests/summarizeText';

export const UploadView = () => {
  const navigate = useNavigate();
  const [statusText, setStatusText] = useState('');
  const [picture, setPicture] = useState<JSX.Element>();
  const [stepsText, setStepsText] = useState('');

  const handleError = (error: AxiosError) => {
    const errorStatusCodes = [504, 500, 400, 524];

    if (
      error?.response?.status &&
      errorStatusCodes.includes(error.response.status)
    ) {
      setPicture(ErrorInfoPicture);
      setStatusText(StatusInformation.errorInfo);
    } else {
      setPicture(ErrorUrlPicture);
      setStatusText(StatusInformation.errorUrl);
    }
  };

  const createTemplateFromUrl = async () => {
    try {
      setPicture(ScrappingPicture);
      setStepsText('Paso 1 de 3');
      setStatusText(StatusInformation.scrapping);

      const text = await scrappingUrl(scrapUrl.value);

      setStepsText('Paso 2 de 3');
      setPicture(SummarizePicture);
      setStatusText(StatusInformation.summarize);

      const textSummarize = await summarizeText(text);

      setPicture(EnhancerPicture);
      setStepsText('Paso 3 de 3');
      setStatusText(StatusInformation.enhance);

      const messages = await generateNewTemplate(textSummarize);

      templateMessages.value = messages;
      initMessageQueue(messages[0]);

      setTimeout(() => {
        navigate('/test');
      }, 1000);
    } catch (error) {
      handleError(error as AxiosError);
    }
  };
  const createTemplateFromContext = async () => {
    try {
      setPicture(EnhancerPicture);
      setStatusText(StatusInformation.enhance);

      const messages = await generateNewTemplate(businessInfo.value);

      templateMessages.value = messages;
      initMessageQueue(messages[0]);

      setTimeout(() => {
        navigate('/test');
      }, 1000);
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  useEffect(() => {
    if (scrapUrl.value) {
      void createTemplateFromUrl();
    } else if (businessInfo.value) {
      void createTemplateFromContext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-screen flex-center flex-col">
      <div className="bg-white max-w-[684px] w-full shadow-lg flex-column justify-center items-center h-[492px] ">
        <div className="flex-center mt-14">{picture}</div>
        <p className="flex-center mt-7 text-gray-800 !font-semibold text-center text-sm ">
          {stepsText}
        </p>
        <div className="text-primary-400  mb-2 font-semibold text-2xl text-center">
          {statusText.split('\n').map((str, index) => (
            <p key={index}>{str}</p>
          ))}
        </div>
        <p className="flex-center text-gray-800 !font-semibold text-center text-sm">
          {StatusInformation.minutes}
        </p>
        <Spinner />
      </div>
    </div>
  );
};
