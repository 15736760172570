export const ErrorUrlPicture = () => {
  return (
    <svg
      width="252"
      height="244"
      viewBox="0 0 252 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M128.245 85.6771C114.319 91.7459 125.975 142.432 73.6167 141.61C53.8604 141.301 29.3 142.2 25.0318 125.888C16.0959 91.518 -8.48262 98.4671 2.99611 61.4289C6.69673 49.4791 14.3023 45.7297 20.2097 37.5293C31.1935 22.2725 75.7417 -6.26589 94.4764 7.86922C110.668 20.0693 114.532 20.9452 131.038 26.1291C166.332 37.2209 155.943 73.6111 128.245 85.6771Z"
        fill="#E7E3FC"
      />
      <path
        d="M171.145 188.93C157.482 182.308 125.418 223.607 92.4876 183.553C80.0605 168.441 63.612 150.476 73.5989 136.795C94.6797 108.011 73.4995 93.9122 109.751 78.8291C121.445 73.9607 129.247 77.2982 139.431 76.4997C158.375 75.0091 209.2 90.3408 210.187 113.547C211.052 133.593 212.846 137.072 219.386 152.856C233.364 186.613 198.317 202.103 171.145 188.93Z"
        fill="#E7E3FC"
      />
      <path
        d="M206.592 30.6387C204.898 26.0579 202.359 21.8936 198.972 18.2845H142.884C139.568 21.8936 136.957 26.0579 135.264 30.6387L143.025 68.6731C157.206 83.8035 181.193 84.7752 196.573 70.8246C207.862 60.5526 211.742 44.7281 206.592 30.6387Z"
        fill="#9378F8"
      />
      <path
        opacity="0.1"
        d="M206.592 30.6387C204.898 26.0579 202.359 21.8936 198.972 18.2845H142.884C139.568 21.8936 136.957 26.0579 135.264 30.6387L143.025 68.6731C157.206 83.8035 181.193 84.7752 196.573 70.8246C207.862 60.5526 211.742 44.7281 206.592 30.6387Z"
        fill="#6C63FF"
      />
      <path
        d="M208.78 45.0752C208.356 53.8203 204.829 62.149 198.761 68.6038H143.026C133.431 58.3317 130.468 43.6871 135.265 30.5694H206.522C208.286 35.289 209.062 40.1474 208.78 45.0752Z"
        fill="#407BFF"
      />
      <path
        opacity="0.9"
        d="M208.78 45.0752C208.356 53.8203 204.829 62.149 198.761 68.6038H143.026C133.431 58.3317 130.468 43.6871 135.265 30.5694H206.522C208.286 35.289 209.062 40.1474 208.78 45.0752Z"
        fill="#FAFAFA"
      />
      <path
        opacity="0.3"
        d="M208.779 45.0752C207.792 65.6193 190.083 81.4438 169.2 80.4721C148.317 79.5005 132.231 62.0796 133.219 41.5355C134.207 20.9914 151.915 5.16691 172.798 6.13859C188.037 6.83264 201.372 16.48 206.592 30.5694C208.286 35.289 209.062 40.1474 208.779 45.0752Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M192.27 12.5934L137.734 61.5244C137.452 61.0386 137.17 60.5528 136.958 59.9975C135.265 56.7355 134.136 53.1958 133.501 49.5867L180.559 7.31854C184.792 8.42903 188.743 10.1642 192.27 12.5934Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M206.874 31.5411L155.654 77.4877C150.363 75.1973 145.636 71.727 141.896 67.3544L197.844 17.1741C201.936 21.1996 205.04 26.1274 206.874 31.5411Z"
        fill="white"
      />
      <path
        d="M208.779 21.8936C196.715 1.28012 169.976 -5.72985 149.022 6.13854C128.068 18.0069 120.943 44.3117 133.007 64.9252C145.071 85.5387 171.81 92.5487 192.764 80.6803C192.764 80.6803 192.764 80.6803 192.835 80.6803C213.718 68.7425 220.844 42.4377 208.779 21.8936ZM141.05 60.3444C131.525 44.1035 137.17 23.3512 153.679 13.9814C170.188 4.6116 191.283 10.1641 200.807 26.405C210.332 42.6459 204.687 63.3983 188.178 72.7681C171.669 82.1378 150.574 76.5854 141.05 60.3444Z"
        fill="#7D60F5"
      />
      <path
        opacity="0.1"
        d="M208.779 21.8936C196.715 1.28012 169.976 -5.72985 149.022 6.13854C128.068 18.0069 120.943 44.3117 133.007 64.9252C145.071 85.5387 171.81 92.5487 192.764 80.6803C192.764 80.6803 192.764 80.6803 192.835 80.6803C213.718 68.7425 220.844 42.4377 208.779 21.8936ZM141.05 60.3444C131.525 44.1035 137.17 23.3512 153.679 13.9814C170.188 4.6116 191.283 10.1641 200.807 26.405C210.332 42.6459 204.687 63.3983 188.178 72.7681C171.669 82.1378 150.574 76.5854 141.05 60.3444Z"
        fill="#3400E9"
      />
      <path
        d="M139.78 66.0358C134.065 70.2001 128.139 74.1563 122.213 78.043C116.286 81.9297 110.29 85.6776 104.293 89.3561C98.2959 93.0346 92.1579 96.6437 86.0905 100.183C83.0568 101.988 79.9525 103.723 76.9188 105.458C75.4372 106.43 73.744 107.124 72.0508 107.61C71.1336 107.818 70.2165 108.026 69.2288 108.096C68.1705 108.165 67.1122 108.096 66.0539 107.957C62.9497 107.332 60.9037 104.348 61.4681 101.294C61.5387 101.086 61.6091 100.808 61.6796 100.6C62.0324 99.6282 62.5262 98.6565 63.0906 97.8236C63.6551 97.0602 64.2195 96.2967 64.925 95.672C66.195 94.4227 67.6766 93.3816 69.2288 92.5488C72.333 90.8136 75.3666 89.0785 78.4709 87.3433C84.6794 83.9425 90.8879 80.5416 97.167 77.2795C103.446 73.948 109.725 70.7554 116.145 67.6321C122.566 64.5089 128.915 61.455 135.476 58.6788C137.664 57.7071 140.274 58.6788 141.262 60.8997C141.967 62.7043 141.403 64.8559 139.78 66.0358Z"
        fill="#8161F1"
      />
      <path
        opacity="0.1"
        d="M139.78 66.0358C134.065 70.2001 128.139 74.1563 122.213 78.043C116.286 81.9297 110.29 85.6776 104.293 89.3561C98.2959 93.0346 92.1579 96.6437 86.0905 100.183C83.0568 101.988 79.9525 103.723 76.9188 105.458C75.4372 106.43 73.744 107.124 72.0508 107.61C71.1336 107.818 70.2165 108.026 69.2288 108.096C68.1705 108.165 67.1122 108.096 66.0539 107.957C62.9497 107.332 60.9037 104.348 61.4681 101.294C61.5387 101.086 61.6091 100.808 61.6796 100.6C62.0324 99.6282 62.5262 98.6565 63.0906 97.8236C63.6551 97.0602 64.2195 96.2967 64.925 95.672C66.195 94.4227 67.6766 93.3816 69.2288 92.5488C72.333 90.8136 75.3666 89.0785 78.4709 87.3433C84.6794 83.9425 90.8879 80.5416 97.167 77.2795C103.446 73.948 109.725 70.7554 116.145 67.6321C122.566 64.5089 128.915 61.455 135.476 58.6788C137.664 57.7071 140.274 58.6788 141.262 60.8997C141.967 62.7043 141.403 64.8559 139.78 66.0358Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.486 104.625C72.6148 104.903 73.1792 105.736 73.2497 106.569C73.3203 107.61 72.121 109.761 72.0504 109.969C71.9799 110.317 73.038 112.676 71.2037 112.885C70.7098 111.566 70.5688 111.011 70.2866 109.9C70.4277 108.859 70.5688 104.903 71.486 104.625Z"
        fill="#FFC7C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.3576 109.969C71.2042 108.026 73.4619 107.193 72.7564 105.25C72.5447 104.625 72.1214 103.723 71.6275 102.751C70.2165 102.543 69.017 102.474 67.6766 102.96C67.7471 103.237 67.7472 103.515 67.7472 103.723C67.6061 104.695 67.1827 105.528 65.9128 107.471C64.6429 109.414 64.5724 109.831 66.4068 113.648C67.7473 116.494 68.4528 118.021 69.9343 117.951C73.3208 117.812 70.0753 113.093 70.3576 109.969Z"
        fill="#FFC7C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.3696 104.07C69.2285 103.584 69.0168 103.168 68.8052 102.751C68.4524 102.821 68.0292 102.89 67.6058 103.029C67.6764 103.306 67.6763 103.584 67.6763 103.792C67.5352 104.764 67.112 105.597 65.8421 107.54C64.5721 109.484 64.5015 109.9 66.3359 113.717C67.1825 115.591 67.8174 116.91 68.5935 117.604C68.9462 117.882 69.5812 117.396 69.299 116.771C68.0291 114.064 66.7592 112.329 66.6886 109.969C66.7592 108.998 70.0751 106.013 69.3696 104.07Z"
        fill="#FFC7C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.2867 110.039C69.8634 109.623 69.1578 109.9 69.5105 109.484C71.4154 107.402 71.6977 106.36 70.7805 104.417C70.7805 107.193 69.7221 108.443 68.7344 109.692L67.2529 110.178C68.4523 110.108 69.1579 110.039 70.2867 110.039Z"
        fill="#FFC7C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.9428 69.5752C74.7312 71.241 72.9674 73.2537 72.1914 75.1971C70.8509 78.3898 69.581 82.4847 67.9583 85.3997C69.0871 87.2043 71.9091 100.114 72.2618 102.612C71.4152 103.376 69.0165 103.792 67.3232 103.723C65.63 99.0727 62.2437 93.7978 61.1148 89.4947C60.9737 89.0088 60.2682 87.8289 60.0566 86.5796C59.8449 85.3997 60.4799 82.1377 60.7621 80.8884C61.397 78.1815 62.5963 71.588 64.5012 67.3542C65.8416 64.231 67.3233 63.7451 69.0166 63.7451C72.8263 63.6757 75.3661 66.2438 74.9428 69.5752Z"
        fill="url(#paint0_linear_479_412)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.5385 159.594C61.2563 162.024 60.833 164.245 59.9864 165.841C59.5631 166.327 58.9987 166.604 58.7871 167.09C57.4466 169.866 57.1644 172.573 58.0816 186.871L61.4681 186.94L61.8913 181.874C62.3146 179.861 63.514 176.807 63.9374 175.002C64.9956 170.977 65.0662 168.964 65.7011 165.98L61.5385 159.594Z"
        fill="#FFA698"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4126 176.807C54.0598 176.738 53.3542 176.738 53.2837 176.668C53.1426 176.46 52.7899 175.558 53.0016 175.35C53.3543 175.003 54.2715 176.53 54.4126 176.807Z"
        fill="#1F202B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.2469 171.116C56.6702 172.365 56.1057 173.615 55.6824 174.933C54.2714 179.583 53.4249 179.861 54.0599 181.943C54.4832 183.401 55.1887 184.581 56.1764 185.622C57.0936 186.663 57.3757 187.426 58.5751 188.19C60.3389 189.37 61.9615 189.994 61.5382 187.565C61.3971 186.732 61.1149 185.691 60.9738 185.275C59.7039 182.36 58.2224 181.11 58.9985 177.501C59.5629 174.794 60.5505 173.059 59.2806 171.255L56.2469 171.116Z"
        fill="#161A40"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.5293 168.201C55.9649 170.63 55.1888 172.643 52.9312 175.349C53.2134 175.28 53.9189 175.974 54.3422 176.807C57.3053 173.476 58.7164 171.879 59.6335 173.267C59.563 174.447 59.0691 176.668 58.7163 178.334C58.5752 178.889 58.4342 180.138 58.787 180.971C59.9158 183.678 62.0323 187.704 61.2563 189.022C61.5385 188.675 61.8911 187.704 61.8911 186.385C61.8911 184.511 61.5384 181.943 61.5384 180.347C61.4678 178.195 62.3145 176.391 62.4556 173.476C62.5261 172.643 62.5261 171.879 62.4556 171.046C62.3145 169.034 62.1028 166.257 60.6212 165.494C60.1273 165.216 58.7164 165.424 58.0814 165.772C57.0937 166.396 56.741 167.298 56.5293 168.201Z"
        fill="#161F69"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.707 131.832L87.078 102.196L87.4307 94.0754C87.5013 92.7567 86.7958 91.785 86.3725 90.744C80.8695 92.3403 77.8358 94.0754 71.9094 93.8672C70.1457 96.4352 67.747 101.294 67.6059 104.972C67.4648 112.121 68.0291 116.841 65.7715 128.778C59.2102 150.572 62.5967 149.808 60.1274 164.245C59.9157 165.772 64.7133 167.021 66.4066 166.049L76.707 131.832Z"
        fill="#46466D"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.7066 131.832L87.0776 102.196L87.4303 94.0754C87.5009 92.7567 86.7954 91.785 86.3721 90.744C85.2433 91.091 84.2556 91.3686 83.2679 91.7156L77.1299 101.363C74.8017 106.083 74.1667 112.121 72.4735 113.578C74.3784 118.159 73.6024 127.46 72.8969 132.526C70.9214 131.902 69.087 131.624 67.1821 130.86C68.5226 131.971 70.9919 133.081 72.3324 134.4C69.8631 143.215 68.6638 152.168 67.8172 161.26L76.7066 131.832Z"
        fill="#1E2353"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.7987 171.532C80.7282 173.823 78.541 175.974 78.2588 177.501C77.6944 180.902 80.1638 179.097 82.9859 183.47C84.1147 183.47 86.5839 187.495 89.9704 187.704C90.7465 187.773 92.1574 185.899 90.6053 184.58C89.8998 184.164 89.3355 184.511 88.7711 184.025C87.5717 182.984 86.2312 174.655 86.5134 172.226L80.7987 171.532Z"
        fill="#FFA698"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.4582 174.864C82.2097 175.211 84.7496 181.804 86.8661 184.65C87.9244 186.038 90.0408 184.58 89.1942 184.233C89.6175 184.233 91.3109 184.095 92.5103 184.233C94.2035 184.511 93.639 186.177 92.4396 187.01C89.8292 188.745 87.501 189.786 85.1728 188.606C84.7495 188.398 84.2556 188.051 83.7618 187.634C83.409 187.357 82.9858 187.079 82.7741 186.871C81.9981 185.969 81.7159 184.511 81.0103 183.262C80.7987 182.845 80.587 182.776 80.4459 182.845C80.1637 183.262 80.4459 189.161 80.587 190.341C80.5164 190.688 79.3171 190.966 79.1055 190.688L76.9183 180.208C76.6361 179.375 76.7772 178.334 77.2005 177.501C77.906 176.321 79.176 175.349 79.4582 174.864Z"
        fill="#161F69"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.6544 189.023C86.1606 188.953 85.7373 188.814 85.2434 188.537C84.8201 188.328 84.3263 187.981 83.8324 187.565C83.4796 187.287 83.0562 187.01 82.8446 186.802C81.998 185.83 81.6452 184.025 80.728 182.776C81.0102 182.568 81.857 182.637 82.6331 183.886C83.8324 186.038 84.6789 187.704 86.2311 188.745C86.3722 188.884 86.5133 188.953 86.6544 189.023Z"
        fill="#161A40"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.768 125.586C96.3202 117.743 96.2495 113.231 95.7557 107.401C95.544 104.07 95.8968 102.473 94.768 99.2807C94.3447 98.0314 93.7803 96.9904 93.1453 95.9493C87.9245 97.5456 84.0441 99.0725 78.1178 98.8643C76.7774 101.432 75.578 104.903 76.0719 108.581C77.0596 115.73 79.5289 121.491 80.305 133.706C78.259 147.934 79.7405 157.512 80.0933 171.324C80.0933 172.851 85.173 174.1 86.6546 173.128C89.7589 157.026 91.7343 141.341 94.768 125.586Z"
        fill="#46466D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.109 59.4419C66.971 58.7479 66.9709 68.6729 69.0874 73.3925C74.661 85.5385 71.0628 87.4125 71.5567 92.9649L71.4156 95.1859C76.707 102.89 91.946 102.265 93.7804 96.7128C93.7098 96.3658 93.6393 94.9083 93.4982 94.5613C93.851 89.7029 91.4523 85.8161 91.9461 82.4847C92.7222 76.8628 95.8264 74.9194 94.1331 70.1304C92.9338 68.4647 89.618 65.8273 86.161 64.0227C81.3635 61.3853 76.0016 59.789 73.109 59.4419Z"
        fill="url(#paint1_linear_479_412)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.109 59.4419C66.971 58.7479 66.9709 68.6729 69.0874 73.3925C74.661 85.5385 71.0628 87.4125 71.5567 92.9649L71.4156 95.1859C76.707 102.89 91.946 102.265 93.7804 96.7128C93.7098 96.3658 93.6393 95.1165 93.4982 94.6307C87.9247 99.8361 79.8818 98.448 75.1549 95.6718C74.7316 93.5896 75.0137 92.6873 75.2959 91.8544C79.1057 87.8289 88.5597 89.2864 90.8879 78.1121C91.5228 74.9194 89.4062 72.0738 87.2897 69.5058C86.302 68.2565 85.526 67.6318 84.1855 65.7579C81.9984 66.2437 77.8359 65.5497 77.342 64.6474C77.2009 64.3698 78.0474 61.5935 77.9063 61.3159C74.0965 59.789 75.2961 59.7196 73.109 59.4419Z"
        fill="url(#paint2_linear_479_412)"
      />
      <path
        d="M126.234 75.1971C126.516 74.9195 126.869 74.7113 127.222 74.6419C127.645 74.5725 127.998 74.7113 128.068 75.0583C128.068 75.2665 127.927 75.4747 127.927 75.6829C127.857 76.1688 128.139 76.5852 128.633 76.7934C129.127 77.0017 129.621 77.0711 130.114 77.0711C131.596 77.1405 133.007 77.2793 134.489 77.4875C134.841 77.4875 135.124 77.6263 135.406 77.8345C135.617 78.1122 135.688 78.4592 135.617 78.8062C135.265 80.4719 133.995 81.9295 132.231 82.7623C130.467 83.5258 128.562 83.8034 126.798 83.5952C124.964 83.387 123.271 82.9011 121.648 82.2765C121.225 82.1377 120.802 81.9295 120.519 81.6518C119.179 79.6391 124.964 76.1688 126.234 75.1971Z"
        fill="#FFC7C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.5473 68.4648C91.17 69.02 92.7927 71.1022 94.5565 72.2821C97.4491 74.2255 101.188 76.3076 103.728 78.5286C105.774 77.8346 119.179 77.8346 121.789 78.0428C122.354 79.0145 122.283 81.3743 121.86 83.04C116.921 83.7341 110.924 85.8856 106.409 86.0245C105.845 86.0245 104.575 86.5103 103.305 86.4409C102.105 86.3715 99.0013 85.1222 97.8019 84.5669C95.2621 83.387 88.983 80.819 85.1027 78.1122C82.2806 76.1688 84.3972 74.6419 84.7499 73.0456C86.6548 70.0611 86.3019 67.3543 89.5473 68.4648Z"
        fill="url(#paint3_linear_479_412)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0137 62.2187C74.5199 64.2314 81.8573 66.3136 84.3971 65.2725L84.4676 64.7867C85.1025 61.8022 85.7375 59.3036 86.2313 57.2215C87.4307 57.7073 90.2528 57.8461 90.535 57.4297C82.7744 51.322 73.7438 46.88 72.5444 49.9339C73.3205 52.1548 75.296 53.7512 76.2837 55.2087C76.5659 55.6251 76.7775 56.3886 76.7775 57.2215C76.4953 58.7484 75.9309 60.2753 75.0137 62.2187Z"
        fill="#F4B8B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.4641 57.4991C89.8292 57.9155 85.0317 56.3886 84.679 56.3886C81.0103 56.5968 77.0595 56.2498 76.2834 55.2087C75.2957 53.7512 73.3202 52.1549 72.5442 49.9339C71.3448 46.3942 71.5565 42.3686 74.8724 39.5924C80.5871 34.734 89.6176 38.2737 90.8875 43.8262C91.3814 46.0471 90.7464 46.2554 90.8875 47.7823C91.0992 50.5585 91.3813 49.5174 91.1697 53.89C91.0286 55.5557 90.8875 57.2215 90.4641 57.4991Z"
        fill="#FFC7C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.072 59.5811C76.2836 61.3857 81.4339 62.635 84.5381 61.9409C84.8909 61.8715 85.1025 61.8021 85.173 61.5939C85.5963 61.8715 86.8663 63.6067 87.0074 64.5089C84.3265 67.0769 75.7897 65.55 74.7314 62.5656C74.5903 61.8021 74.3787 60.4834 76.072 59.5811Z"
        fill="url(#paint4_linear_479_412)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.8906 53.6123C56.952 57.6378 54.2005 64.9254 56.4582 69.0898C58.5042 72.8377 63.4427 75.0586 61.8906 77.4184C60.6207 79.3618 57.6575 81.3052 61.1851 84.4978C63.0194 86.0942 67.3937 90.4667 76.4242 88.2457C85.2432 86.0248 94.4853 78.5289 87.0774 70.4085C79.2462 61.8022 83.8321 59.9282 88.3474 53.1264C90.7461 49.5173 92.8626 42.5074 90.8167 39.8699C87.3596 35.3586 85.0315 38.6206 81.7156 37.0243C75.366 33.9705 70.0746 36.1914 67.1115 40.911C64.36 45.006 65.7709 50.3502 61.8906 53.6123Z"
        fill="#22043D"
      />
      <defs>
        <linearGradient
          id="paint0_linear_479_412"
          x1="62.8404"
          y1="76.0466"
          x2="71.7454"
          y2="86.4016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#89DFCF" />
          <stop offset="1" stopColor="#5DE2CC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_479_412"
          x1="67.789"
          y1="80.1929"
          x2="94.6173"
          y2="80.1929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B1FCED" />
          <stop offset="1" stopColor="#5DE2CC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_479_412"
          x1="67.789"
          y1="80.1929"
          x2="93.7972"
          y2="80.1929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DE2CC" />
          <stop offset="1" stopColor="#89DFCF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_479_412"
          x1="128.51"
          y1="88.8433"
          x2="100.397"
          y2="76.9829"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#89DFCF" />
          <stop offset="1" stopColor="#5DE2CC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_479_412"
          x1="74.6566"
          y1="62.6583"
          x2="87.0944"
          y2="62.6583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#89DFCF" />
          <stop offset="1" stopColor="#5DE2CC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
