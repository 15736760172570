export const SummarizePicture = () => {
  return (
    <svg
      width="215"
      height="196"
      viewBox="0 0 215 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.3514 40.2409C37.8011 52.5128 10.7067 87.3082 10.7067 87.3082C6.21498 93.5943 1.58391 100.178 0.34067 108.035C-0.626298 114.096 0.61695 120.382 2.55202 126.144C5.10871 133.702 9.39442 141.11 16.2357 144.403C26.2564 149.192 38.5586 143.654 48.7865 147.92C61.9209 153.38 65.9258 172.165 77.9507 180.022C82.927 183.314 88.9337 184.362 94.7446 184.96C108.705 186.457 123.563 184.885 132.063 171.117C139.182 159.444 141.186 148.519 151.207 138.865C156.919 133.313 163.25 128.441 170.074 124.348C176.981 120.456 184.454 116.79 190.6 111.852C197.167 106.54 203.317 100.553 207.811 93.1449C212.234 85.7372 214.86 76.7574 213.754 68.0767C211.819 53.3352 200.071 42.7094 188.322 35.1515C174.362 26.1717 158.883 19.5124 142.709 17.4921C131.168 16.0702 124.395 16.6686 112.784 17.6423C106.217 18.2407 90.2517 23.7782 83.9653 25.7985"
        fill="#F1F0FC"
      />
      <path
        d="M181.973 170.125H36.4311C32.3776 170.125 29.0996 166.063 29.0996 161.04V42.3191C29.0996 37.2957 32.3832 33.2335 36.4311 33.2335H181.973C186.027 33.2335 189.305 37.2957 189.305 42.3191V161.04C189.306 166.063 186.027 170.125 181.973 170.125Z"
        fill="white"
      />
      <path
        d="M154.225 123.655V154.923C154.22 157.098 153.383 159.188 151.886 160.759C150.389 162.331 148.348 163.263 146.185 163.364H14.4638C12.6474 163.362 10.8809 162.767 9.43074 161.668C7.98058 160.569 6.92547 159.026 6.42458 157.272C6.21526 156.507 6.11687 155.716 6.13246 154.923V123.655C6.13276 121.417 7.01802 119.27 8.59355 117.687C10.1691 116.104 12.3059 115.215 14.534 115.215H145.896C146.995 115.216 148.083 115.436 149.098 115.862C150.113 116.287 151.033 116.91 151.808 117.695C152.582 118.479 153.194 119.409 153.608 120.432C154.023 121.455 154.233 122.551 154.225 123.655Z"
        fill="#94E2D3"
      />
      <path
        d="M154.225 126.812V154.923C154.22 157.098 153.383 159.188 151.886 160.76C150.389 162.331 148.348 163.264 146.185 163.364H14.4638C12.6474 163.362 10.8809 162.767 9.43074 161.668C7.98058 160.569 6.92547 159.026 6.42458 157.272C6.21526 156.507 6.11687 155.716 6.13246 154.923V126.812H154.225Z"
        fill="#94E2D3"
      />
      <path
        d="M29.9524 131.436C31.5068 131.436 33.0461 131.743 34.4823 132.34C35.9185 132.938 37.2234 133.814 38.3226 134.918C39.4217 136.022 40.2935 137.333 40.8881 138.776C41.4828 140.219 41.7886 141.765 41.7882 143.327C41.7886 144.889 41.4828 146.435 40.8881 147.878C40.2935 149.321 39.4217 150.632 38.3226 151.736C37.2234 152.84 35.9185 153.716 34.4823 154.313C33.0461 154.911 31.5068 155.218 29.9524 155.218C28.398 155.218 26.8587 154.911 25.4226 154.313C23.9864 153.716 22.6814 152.84 21.5823 151.736C20.4832 150.632 19.6114 149.321 19.0167 147.878C18.4221 146.435 18.1163 144.889 18.1167 143.327C18.1163 141.765 18.4221 140.219 19.0167 138.776C19.6114 137.333 20.4832 136.022 21.5823 134.918C22.6814 133.814 23.9864 132.938 25.4226 132.34C26.8587 131.743 28.398 131.436 29.9524 131.436Z"
        fill="white"
      />
      <path
        d="M19.2127 151.547L26.3721 146.555C26.6196 145.791 26.7911 145.004 26.8839 144.206C26.207 143.603 25.7238 142.811 25.4957 141.931C25.4859 141.931 25.4762 141.932 25.4672 141.936C25.4581 141.94 25.4499 141.945 25.443 141.952C25.4361 141.959 25.4307 141.967 25.4271 141.976C25.4235 141.985 25.4218 141.995 25.4221 142.005C24.7643 142.519 23.5957 137.16 25.6418 136.133C25.6418 136.133 27.176 132.903 30.0248 133.637C32.8736 134.371 34.5539 135.546 34.6275 136.646C34.6979 137.6 34.6732 138.559 34.5539 139.508C34.508 139.153 34.3863 138.811 34.1971 138.507C34.008 138.203 33.7559 137.943 33.4579 137.746C34.2593 138.672 34.6631 139.879 34.5809 141.103C34.4988 142.328 33.9375 143.469 33.0197 144.279C32.8073 144.491 32.5602 144.665 32.2894 144.793C32.509 145.527 32.8012 146.481 33.0197 147.215L35.2118 148.316L39.9606 151.325C41.1352 152.846 41.9813 154.596 42.4448 156.463C42.5184 156.684 42.5184 156.904 42.5909 157.124H17.2402C18.1166 154.483 19.2127 151.547 19.2127 151.547Z"
        fill="#E6E6E6"
      />
      <path
        d="M60.1943 140.757H129.162C129.471 140.717 129.751 140.555 129.942 140.308C130.133 140.06 130.219 139.747 130.181 139.436C130.219 139.126 130.133 138.812 129.942 138.565C129.751 138.318 129.47 138.156 129.162 138.116H60.1943C59.8854 138.156 59.605 138.318 59.4141 138.565C59.2232 138.812 59.1374 139.126 59.1753 139.436C59.1383 139.747 59.2245 140.059 59.4152 140.307C59.6059 140.554 59.8858 140.716 60.1943 140.757Z"
        fill="white"
      />
      <path
        d="M60.1943 133.05H81.4586C81.7675 133.01 82.0479 132.848 82.2388 132.601C82.4296 132.353 82.5155 132.04 82.4776 131.73C82.5155 131.419 82.4296 131.106 82.2388 130.858C82.0479 130.611 81.7675 130.449 81.4586 130.409H60.1943C59.8854 130.449 59.605 130.611 59.4141 130.858C59.2232 131.106 59.1374 131.419 59.1753 131.73C59.1383 132.04 59.2245 132.353 59.4152 132.6C59.6059 132.847 59.8858 133.009 60.1943 133.05Z"
        fill="white"
      />
      <path
        d="M86.4111 133.05H138.609C139.144 133.05 139.545 132.463 139.545 131.73C139.545 130.996 139.144 130.409 138.609 130.409H86.4111C85.8767 130.409 85.4758 130.996 85.4758 131.73C85.4758 132.536 85.8767 133.05 86.4111 133.05Z"
        fill="white"
      />
      <path
        d="M133.11 163.364V172.612C133.113 172.747 133.088 172.881 133.038 173.007C132.988 173.132 132.913 173.246 132.817 173.342C132.722 173.438 132.609 173.513 132.484 173.563C132.359 173.614 132.225 173.638 132.091 173.636C131.965 173.65 131.838 173.63 131.722 173.579C131.607 173.527 131.507 173.445 131.433 173.342L121.494 163.364H133.11Z"
        fill="#94E2D3"
      />
      <path
        d="M31.5589 155.511C41.8476 155.511 50.1883 147.493 50.1883 137.602C50.1883 127.71 41.8476 119.692 31.5589 119.692C21.2701 119.692 12.9294 127.71 12.9294 137.602C12.9294 147.493 21.2701 155.511 31.5589 155.511Z"
        fill="#52C4B5"
      />
      <path
        d="M27.76 94.4429C24.8376 94.5897 23.6691 97.6724 23.3034 98.4062C23.0111 99.1286 22.8622 99.9015 22.8652 100.681C22.8532 101.476 22.653 102.255 22.281 102.956C21.6231 103.98 20.2349 104.572 19.8703 105.746C19.4321 107.066 20.5282 108.461 20.2361 109.856C20.09 110.589 19.5782 111.176 19.3597 111.911C19.2172 112.689 19.3464 113.493 19.7254 114.186C20.2646 114.989 20.9945 115.645 21.8491 116.093C22.7038 116.542 23.6562 116.769 24.6202 116.755C26.5676 116.709 28.4937 116.337 30.319 115.654"
        fill="#484848"
      />
      <path
        d="M40.7643 98.3333C40.652 98.6607 40.6336 99.0133 40.711 99.3508C40.7885 99.6883 40.9588 99.9971 41.2025 100.242C41.7052 100.722 42.2426 101.163 42.8103 101.563C43.3822 101.959 43.7759 102.565 43.9064 103.251C43.98 103.912 43.6143 104.502 43.7603 105.16C43.98 106.775 46.5367 107.288 46.6827 108.903C46.7563 110.005 45.6637 110.959 45.9524 112.06C46.1167 112.458 46.3384 112.829 46.6103 113.161C46.9213 113.796 47.0111 114.517 46.8653 115.209C46.7196 115.901 46.3466 116.524 45.8064 116.978C44.674 117.846 43.286 118.311 41.8615 118.298C41.2769 118.395 40.6776 118.344 40.1169 118.152C39.5562 117.96 39.0513 117.632 38.6469 117.197C38.1245 116.232 37.9935 115.101 38.2812 114.041C38.7689 108.943 38.8911 103.816 38.6469 98.7007"
        fill="#484848"
      />
      <path
        d="M28.5641 121.82V116.316C28.5641 116.316 25.1299 115.729 24.4732 111.251C23.8153 106.995 24.9114 97.7464 24.9114 97.7464C29.8798 95.7647 35.4314 96.5724 38.8656 100.536L38.3538 122.188C38.3156 122.805 38.1568 123.408 37.8864 123.963C37.6159 124.519 37.2393 125.015 36.7779 125.424C36.3164 125.833 35.7793 126.146 35.1972 126.347C34.6151 126.547 33.9995 126.63 33.3854 126.591C32.1289 126.56 30.9314 126.049 30.036 125.163C29.1406 124.277 28.6144 123.082 28.5641 121.82Z"
        fill="#FFC7C6"
      />
      <path
        d="M28.4905 116.316C30.5943 116.399 32.6765 115.864 34.4814 114.774C34.4814 114.774 33.0207 117.857 28.5641 117.343L28.4905 116.316Z"
        fill="#F9BDBD"
      />
      <path
        d="M36.892 97.3061C35.2842 99.6552 30.9011 102.077 27.8337 102.591C28.1995 102.151 30.0983 100.903 30.464 100.463C29.0759 101.487 27.0298 102.959 25.2034 103.031C24.9816 103.043 24.7594 103.018 24.5456 102.957C24.4287 102.879 24.3382 102.767 24.2861 102.636C24.2339 102.505 24.2226 102.361 24.2535 102.224C24.1561 100.877 24.205 99.523 24.3995 98.1866C24.5439 97.0588 25.1203 96.0321 26.0062 95.3245C27.3461 94.443 28.9382 94.0303 30.5354 94.1506C31.8711 94.0629 33.211 94.2375 34.4802 94.6647C35.0671 94.8647 35.5984 95.2019 36.0303 95.6484C36.4622 96.095 36.7823 96.6382 36.9645 97.2333"
        fill="#484848"
      />
      <path
        d="M41.6407 107.801C41.8633 106.811 42.0098 105.805 42.0789 104.792C42.225 102.443 41.5671 93.9299 33.6038 94.6681L33.3116 97.09C33.3116 97.09 32.0661 105.384 37.2565 105.458C37.6123 107.313 38.5011 109.022 39.8132 110.375C39.7396 110.375 41.0587 110.229 41.6396 107.807"
        fill="#484848"
      />
      <path
        d="M37.1852 105.453C37.6715 105.368 38.1706 105.393 38.6459 105.527C39.0961 105.7 39.4616 106.043 39.6649 106.482C39.811 106.935 39.811 107.423 39.6649 107.876C39.4838 108.321 39.2096 108.721 38.861 109.05C38.6113 109.303 38.3132 109.503 37.9846 109.637C37.817 109.705 37.6344 109.727 37.4555 109.702C37.2765 109.676 37.1075 109.603 36.9656 109.491"
        fill="#FFC7C6"
      />
      <path
        d="M38.792 109.417C38.3266 109.722 37.9072 110.093 37.5465 110.518C37.3577 110.724 37.2145 110.968 37.1262 111.233C37.038 111.499 37.0068 111.78 37.0347 112.059C37.0954 112.348 37.1202 112.644 37.1083 112.94C36.9441 113.337 36.7224 113.708 36.4505 114.041C36.2013 114.521 36.1028 115.065 36.1681 115.602C36.2334 116.139 36.4594 116.644 36.8162 117.05C37.1998 117.432 37.6563 117.734 38.1584 117.936C38.6606 118.137 39.198 118.235 39.7386 118.224C40.38 118.298 41.027 118.142 41.565 117.783C41.9141 117.49 42.1887 117.117 42.3659 116.696C42.5431 116.275 42.6179 115.817 42.584 115.361"
        fill="#484848"
      />
      <path
        opacity="0.3"
        d="M18.6282 146.116C18.6282 146.116 29.3678 156.319 35.7256 154.484C37.5026 154.041 39.162 153.214 40.5878 152.06C42.0137 150.907 43.1717 149.454 43.9811 147.804L44.1271 146.042L43.4693 145.602L18.9214 143.62L18.6282 146.116Z"
        fill="black"
      />
      <path
        d="M12.2717 136.574L14.3178 131.289L21.6968 135.988L15.1217 146.19C13.3222 143.304 12.3372 139.98 12.2717 136.574Z"
        fill="#F9BDBD"
      />
      <path
        d="M20.6008 121.893C23.0889 120.691 25.7531 119.897 28.4917 119.544C29.8062 122.113 33.8983 122.48 36.3044 120.718L38.4229 119.323C38.717 119.311 39.0113 119.336 39.2993 119.397C41.6853 119.62 43.9356 120.612 45.7135 122.226C47.4914 123.841 48.7014 125.99 49.1626 128.352C49.5893 130.203 49.7614 132.105 49.6744 134.003C49.3822 138.774 44.5599 139.142 44.5599 139.142C44.5599 139.142 44.3402 143.839 43.9756 147.582C43.9072 148.894 43.4743 150.161 42.726 151.238C41.9778 152.316 40.944 153.161 39.742 153.678C38.0405 154.526 36.2154 155.096 34.3354 155.367C31.378 155.83 28.3527 155.557 25.5248 154.572C22.697 153.587 20.1531 151.919 18.1166 149.715C18.8469 145.238 19.5772 139.364 19.5047 139.146V139.072L14.6824 134.742L11.906 132.393C11.906 132.319 17.8969 123.365 20.5997 121.896"
        fill="white"
      />
      <path
        d="M6.57287 118.297C6.865 118.591 16.2901 131.362 16.2901 131.362L14.9755 132.096L5.2583 119.471L6.57287 118.297Z"
        fill="#263238"
      />
      <path
        d="M43.5407 127.399C43.5407 127.399 33.8971 143.552 33.3128 143.84C32.1443 144.427 19.7968 134.298 18.5547 133.197C18.0177 131.737 17.383 130.315 16.6547 128.94C16.6547 128.94 15.5586 124.023 14.4626 124.39C13.2941 124.758 15.1205 126.886 14.3165 128.794C13.5862 130.702 10.152 126.225 10.0059 125.785L9.12954 124.611C8.67497 125.815 8.38032 127.073 8.25317 128.355C8.47284 131.881 15.4862 136.355 15.4862 136.355C15.4862 136.355 25.2759 153.089 35.5038 153.678C38.4262 153.824 44.0514 145.31 47.558 139.003C49.6765 135.187 48.6541 131.37 45.2199 128.8L43.5407 127.399Z"
        fill="#FFC7C6"
      />
      <path
        d="M43.1024 122.996C42.7367 123.216 34.4812 138.629 34.4812 138.629L45.1473 146.997C46.7769 142.942 48.1913 138.803 49.3843 134.597C49.7373 132.223 49.6129 129.802 49.0186 127.477C48.2146 125.275 47.0461 125.789 45.2197 124.541L43.1024 122.996Z"
        fill="white"
      />
      <path
        d="M100.846 150.341H60.0722C58.8968 150.309 57.7566 150.745 56.9006 151.555C56.0447 152.365 55.5428 153.483 55.5046 154.664V174.116C55.5192 174.702 55.6492 175.279 55.8872 175.814C56.1252 176.349 56.4665 176.832 56.8912 177.234C57.316 177.636 57.8157 177.949 58.3617 178.156C58.9077 178.363 59.4891 178.459 60.0722 178.438H64.7225V184.76C64.733 184.941 64.812 185.111 64.9434 185.235C65.0748 185.359 65.2487 185.428 65.4291 185.427C65.6084 185.415 65.7807 185.353 65.9272 185.249L73.1104 178.483H100.805C101.98 178.515 103.12 178.078 103.976 177.268C104.832 176.458 105.333 175.341 105.371 174.16V154.662C105.357 154.08 105.229 153.506 104.994 152.973C104.759 152.44 104.421 151.96 104.001 151.558C103.581 151.157 103.086 150.843 102.544 150.634C102.003 150.425 101.426 150.325 100.846 150.341Z"
        fill="white"
        stroke="#52C4B5"
        strokeWidth="3.9524"
      />
      <path
        d="M100.846 150.341H60.0722C58.8968 150.309 57.7566 150.745 56.9006 151.555C56.0447 152.365 55.5428 153.483 55.5046 154.664V174.116C55.5192 174.702 55.6492 175.279 55.8872 175.814C56.1252 176.349 56.4665 176.832 56.8912 177.234C57.316 177.636 57.8157 177.949 58.3617 178.156C58.9077 178.363 59.4891 178.459 60.0722 178.438H64.7225V184.76C64.733 184.941 64.812 185.111 64.9434 185.235C65.0748 185.359 65.2487 185.428 65.4291 185.427C65.6084 185.415 65.7807 185.353 65.9272 185.249L73.1104 178.483H100.805C101.98 178.515 103.12 178.078 103.976 177.268C104.832 176.458 105.333 175.341 105.371 174.16V154.662C105.357 154.08 105.229 153.506 104.994 152.973C104.759 152.44 104.421 151.96 104.001 151.558C103.581 151.157 103.086 150.843 102.544 150.634C102.003 150.425 101.426 150.325 100.846 150.341Z"
        fill="#52C4B5"
      />
      <path
        d="M71.6384 167.198C73.0616 167.198 74.2154 166.039 74.2154 164.609C74.2154 163.179 73.0616 162.02 71.6384 162.02C70.2151 162.02 69.0613 163.179 69.0613 164.609C69.0613 166.039 70.2151 167.198 71.6384 167.198Z"
        fill="white"
      />
      <path
        d="M80.2822 167.198C81.7054 167.198 82.8592 166.039 82.8592 164.609C82.8592 163.179 81.7054 162.02 80.2822 162.02C78.8589 162.02 77.7051 163.179 77.7051 164.609C77.7051 166.039 78.8589 167.198 80.2822 167.198Z"
        fill="white"
      />
      <path
        d="M88.925 167.198C90.3483 167.198 91.502 166.039 91.502 164.609C91.502 163.179 90.3483 162.02 88.925 162.02C87.5017 162.02 86.3479 163.179 86.3479 164.609C86.3479 166.039 87.5017 167.198 88.925 167.198Z"
        fill="white"
      />
      <path
        d="M202.058 32.3326V63.6005C202.053 65.7755 201.216 67.8654 199.719 69.4369C198.222 71.0084 196.181 71.9409 194.018 72.0411H62.2968C60.4804 72.0393 58.7139 71.4441 57.2637 70.3453C55.8136 69.2464 54.7585 67.7036 54.2576 65.9495C54.0483 65.1847 53.9499 64.3935 53.9655 63.6005V32.3326C53.9658 30.0941 54.851 27.9474 56.4266 26.3646C58.0021 24.7817 60.1389 23.8923 62.367 23.892H193.729C194.828 23.8935 195.916 24.1133 196.931 24.5389C197.946 24.9645 198.866 25.5875 199.641 26.3719C200.415 27.1564 201.027 28.0869 201.441 29.1098C201.856 30.1328 202.066 31.2281 202.058 32.3326Z"
        fill="#C5B5FF"
      />
      <path
        d="M202.058 35.4894V63.6006C202.053 65.7756 201.216 67.8655 199.719 69.437C198.222 71.0084 196.181 71.941 194.018 72.0412H62.2968C60.4804 72.0394 58.7139 71.4442 57.2637 70.3454C55.8136 69.2465 54.7585 67.7037 54.2576 65.9496C54.0483 65.1848 53.9499 64.3936 53.9655 63.6006V35.4894H202.058Z"
        fill="#C5B5FF"
      />
      <path
        d="M77.7854 40.1135C79.3398 40.113 80.8791 40.4203 82.3153 41.0177C83.7515 41.6151 85.0564 42.491 86.1556 43.5952C87.2547 44.6995 88.1265 46.0105 88.7211 47.4534C89.3158 48.8962 89.6216 50.4426 89.6212 52.0043C89.6216 53.5659 89.3158 55.1124 88.7211 56.5552C88.1265 57.9981 87.2547 59.3091 86.1556 60.4134C85.0564 61.5176 83.7515 62.3935 82.3153 62.9909C80.8791 63.5883 79.3398 63.8955 77.7854 63.8951C76.231 63.8955 74.6917 63.5883 73.2556 62.9909C71.8194 62.3935 70.5145 61.5176 69.4153 60.4134C68.3162 59.3091 67.4444 57.9981 66.8497 56.5552C66.2551 55.1124 65.9493 53.5659 65.9497 52.0043C65.9493 50.4426 66.2551 48.8962 66.8497 47.4534C67.4444 46.0105 68.3162 44.6995 69.4153 43.5952C70.5145 42.491 71.8194 41.6151 73.2556 41.0177C74.6917 40.4203 76.231 40.113 77.7854 40.1135Z"
        fill="white"
      />
      <path
        d="M67.0457 60.2241L74.2051 55.2325C74.4526 54.4684 74.6241 53.6816 74.7169 52.8835C74.04 52.28 73.5568 51.4882 73.3287 50.6084C73.319 50.6081 73.3093 50.6098 73.3002 50.6134C73.2911 50.617 73.2829 50.6224 73.276 50.6293C73.2691 50.6363 73.2637 50.6445 73.2601 50.6536C73.2565 50.6628 73.2548 50.6725 73.2551 50.6823C72.5973 51.1965 71.4288 45.8375 73.4748 44.8103C73.4748 44.8103 75.009 41.5808 77.8578 42.3145C80.7066 43.0482 82.387 44.2233 82.4606 45.3233C82.5309 46.2774 82.5063 47.2362 82.387 48.1854C82.341 47.8298 82.2193 47.4884 82.0301 47.1843C81.841 46.8803 81.5889 46.6207 81.2909 46.4233C82.0923 47.3493 82.4961 48.5564 82.414 49.7807C82.3318 51.0049 81.7705 52.1467 80.8527 52.9563C80.6403 53.1683 80.3933 53.3422 80.1224 53.4704C80.3421 54.2042 80.6342 55.1586 80.8527 55.8923L83.0448 56.9934L87.7936 60.0023C88.9682 61.5229 89.8143 63.2729 90.2778 65.1406C90.3514 65.3612 90.3514 65.5808 90.4239 65.8015H65.0732C65.9496 63.1601 67.0457 60.2241 67.0457 60.2241Z"
        fill="#E6E6E6"
      />
      <path
        d="M107.628 57.3972H149.415C149.769 57.3972 150.034 56.8103 150.034 56.0766C150.034 55.3428 149.768 54.7559 149.415 54.7559H107.628C107.273 54.7559 107.008 55.3428 107.008 56.0766C107.008 56.8831 107.319 57.3972 107.628 57.3972Z"
        fill="white"
      />
      <path
        d="M108.027 49.4344H176.995C177.304 49.3941 177.584 49.2326 177.775 48.9852C177.966 48.7377 178.052 48.4245 178.014 48.1137C178.052 47.8031 177.966 47.4899 177.775 47.2426C177.584 46.9952 177.303 46.8336 176.995 46.793H108.027C107.718 46.8336 107.438 46.9952 107.247 47.2426C107.056 47.4899 106.97 47.8031 107.008 48.1137C106.971 48.4242 107.057 48.7369 107.248 48.984C107.439 49.2312 107.719 49.393 108.027 49.4344Z"
        fill="white"
      />
      <path
        d="M108.027 41.8459H176.995C177.304 41.8056 177.584 41.6441 177.775 41.3967C177.966 41.1492 178.052 40.836 178.014 40.5252C178.052 40.2146 177.966 39.9014 177.775 39.6541C177.584 39.4067 177.303 39.2451 176.995 39.2045H108.027C107.718 39.2451 107.438 39.4067 107.247 39.6541C107.056 39.9014 106.97 40.2146 107.008 40.5252C106.971 40.8357 107.057 41.1484 107.248 41.3955C107.439 41.6427 107.719 41.8045 108.027 41.8459Z"
        fill="white"
      />
      <path
        d="M180.943 72.041V81.2893C180.946 81.4245 180.921 81.5589 180.871 81.6844C180.821 81.8098 180.746 81.9238 180.65 82.0195C180.555 82.1151 180.442 82.1904 180.317 82.2409C180.192 82.2913 180.058 82.3159 179.924 82.3131C179.798 82.3273 179.671 82.3076 179.555 82.256C179.44 82.2044 179.34 82.1228 179.266 82.0196L169.327 72.041H180.943Z"
        fill="#C5B5FF"
      />
      <path
        d="M79.187 65.0212C89.4758 65.0212 97.8165 57.0028 97.8165 47.1116C97.8165 37.2204 89.4758 29.202 79.187 29.202C68.8983 29.202 60.5576 37.2204 60.5576 47.1116C60.5576 57.0028 68.8983 65.0212 79.187 65.0212Z"
        fill="#9378F8"
      />
      <path
        d="M81.0135 36.7622C81.629 36.8075 82.2472 36.729 82.832 36.5311C83.4169 36.3332 83.9565 36.0201 84.4193 35.6099C84.882 35.1998 85.2586 34.7009 85.527 34.1426C85.7953 33.5844 85.95 32.9779 85.982 32.3587L86.4937 10.7066C83.0595 6.74336 77.4355 5.9357 72.5395 7.91731C72.5395 7.91731 71.5205 17.0917 72.1013 21.4223C72.7592 25.8997 76.1923 26.4867 76.1923 26.4867V31.9913C76.2063 33.2649 76.721 34.4812 77.6242 35.3749C78.5274 36.2687 79.7458 36.7674 81.0135 36.7622Z"
        fill="#FFC7C6"
      />
      <path
        d="M76.1923 27.6606C80.6489 28.1009 82.1095 25.092 82.1095 25.092C80.291 26.148 78.2185 26.6813 76.1187 26.6334L76.1923 27.6606Z"
        fill="#F9BDBD"
      />
      <path
        d="M84.5938 7.40213C84.4186 6.74366 84.0781 6.1414 83.6049 5.65301C83.1317 5.16463 82.5416 4.80648 81.8911 4.61286C80.5086 4.12602 79.0421 3.92631 77.5805 4.02587C75.8531 3.95528 74.1454 4.41604 72.6856 5.34657C71.7227 6.13596 71.0955 7.26442 70.9317 8.50214C70.7373 9.96142 70.6883 11.4365 70.7857 12.9056C70.7846 13.0482 70.8172 13.1891 70.8808 13.3166C70.9444 13.4441 71.0371 13.5547 71.1514 13.6393C71.3812 13.7399 71.6365 13.7658 71.8817 13.7133C73.9277 13.6393 76.0462 12.0979 77.5805 10.924C77.2148 11.4382 75.0962 12.686 74.7316 13.1991C78.0923 12.6121 82.7709 9.96958 84.5214 7.4738"
        fill="#484848"
      />
      <path
        d="M89.1966 18.0451C88.9732 18.8403 88.584 19.5788 88.055 20.2114C87.526 20.8441 86.8692 21.3566 86.1281 21.7149C84.8135 20.3942 85.109 17.5309 84.8135 15.6961C79.699 15.6221 80.8686 7.32825 80.8686 7.32825L81.1607 4.90641C89.1241 4.2455 89.7819 12.7555 89.6359 15.0306C89.5668 16.0433 89.4203 17.0492 89.1977 18.0394"
        fill="#484848"
      />
      <path
        d="M84.5938 19.0734C84.7357 19.1859 84.9047 19.2587 85.0836 19.2845C85.2626 19.3102 85.4451 19.288 85.6128 19.2201C85.9551 19.1132 86.2594 18.9094 86.4892 18.6331C86.8451 18.3105 87.1204 17.9084 87.2931 17.4592C87.4393 17.0059 87.4393 16.5178 87.2931 16.0645C87.0898 15.6255 86.7243 15.2831 86.2741 15.1101C85.8058 14.9378 85.2965 14.912 84.8134 15.0362"
        fill="#FFC7C6"
      />
      <path
        opacity="0.3"
        d="M66.5486 53.2772L91.0965 55.2588L91.7543 55.6991L91.6083 57.4611C90.7872 59.1025 89.6255 60.548 88.2014 61.7003C86.7774 62.8525 85.124 63.6847 83.3528 64.1408C76.9962 65.9757 66.2554 55.773 66.2554 55.773L66.5486 53.2772Z"
        fill="black"
      />
      <path
        d="M62.7488 55.7013L69.324 45.4986L61.9449 40.8016L59.9 46.0844C59.8879 49.5017 60.8788 52.8467 62.7488 55.7013Z"
        fill="#F9BDBD"
      />
      <path
        d="M68.2288 31.4044C65.5261 32.8718 59.5352 41.8266 59.5352 41.8266L62.3115 44.1756L67.1339 48.5063V48.5802C67.2075 48.8737 66.476 54.6718 65.7457 59.1492C67.7822 61.3529 70.3261 63.0206 73.154 64.0059C75.9818 64.9913 79.0072 65.2641 81.9645 64.8006C83.8469 64.5407 85.6737 63.9703 87.3712 63.1125C88.5758 62.5996 89.6121 61.7561 90.3615 60.6787C91.1109 59.6013 91.5429 58.3336 91.6081 57.0209C91.9739 53.2044 92.1924 48.5802 92.1924 48.5802C92.1924 48.5802 97.0873 48.14 97.3069 43.4419C97.3576 41.5445 97.1858 39.6477 96.7951 37.7906C96.322 35.4338 95.1083 33.2918 93.3328 31.6798C91.5572 30.0677 89.3135 29.0709 86.9318 28.8358C86.6397 28.8358 86.3476 28.7618 86.0554 28.7618L83.9369 30.1565C81.4527 31.9185 77.3618 31.6239 76.1242 28.9825C73.395 29.4188 70.7393 30.2339 68.2333 31.4044"
        fill="white"
      />
      <path
        d="M52.8865 29.0589L62.6037 41.6095L63.9183 40.8758L54.2011 27.8076L52.8865 29.0589Z"
        fill="#263238"
      />
      <path
        d="M92.8493 38.2297C96.3594 40.7983 97.306 44.6887 95.1875 48.4324C91.6774 54.7447 86.0556 63.2592 83.1332 63.1068C72.9053 62.5198 63.1156 45.7853 63.1156 45.7853C63.1156 45.7853 56.1022 41.3079 55.8826 37.7849C56.0097 36.5038 56.3044 35.2451 56.759 34.0413L57.6353 35.2152C57.7814 35.6554 61.2156 40.1329 61.9459 38.224C62.6763 36.3152 60.9269 34.1869 62.092 33.8206C63.2571 33.4543 64.2841 38.3708 64.2841 38.3708C65.0124 39.745 65.6472 41.1671 66.1841 42.6275C67.4987 43.7287 79.7715 53.8574 80.9422 53.2704C81.5265 52.9769 91.1701 36.8294 91.1701 36.8294L92.8493 38.2297Z"
        fill="#FFC7C6"
      />
      <path
        d="M92.8494 34.1198C94.6758 35.4405 95.8443 34.8535 96.6482 37.0558C97.2426 39.3807 97.3669 41.8017 97.0139 44.1757C95.8209 48.3812 94.4066 52.5201 92.7769 56.575L82.1108 48.2072C82.1108 48.2072 90.3663 32.7934 90.732 32.5739L92.8494 34.1198Z"
        fill="white"
      />
      <path
        d="M88.6847 16.7243C88.7349 16.1573 88.8963 15.606 89.1596 15.1021C89.4229 14.5982 89.783 14.1517 90.2189 13.7882C90.6601 13.3932 90.9727 12.8739 91.1161 12.2979C91.2595 11.7219 91.227 11.1159 91.0228 10.5587C90.7307 9.38478 90.0038 9.53494 89.7083 8.79667C89.5481 8.27471 89.4984 7.72489 89.5622 7.18248C89.5124 6.74541 89.3754 6.32293 89.1593 5.94029C88.9432 5.55765 88.6526 5.22272 88.3047 4.95553C87.9568 4.68834 87.5589 4.49438 87.1347 4.38525C86.7105 4.27612 86.2688 4.25407 85.8359 4.3204C85.7961 4.31546 85.7557 4.31954 85.7177 4.33234C85.6797 4.34513 85.645 4.36632 85.6162 4.39435C85.4552 3.80507 85.1797 3.25364 84.8056 2.77185C84.4314 2.29006 83.966 1.88746 83.4363 1.58724C82.9065 1.28701 82.3228 1.09512 81.7189 1.02263C81.1149 0.950131 80.5027 0.998468 79.9174 1.16485C79.3059 1.3354 78.7366 1.63246 78.246 2.03698C77.7554 2.4415 77.3544 2.94455 77.0686 3.51388C76.6566 3.29929 76.2063 3.16893 75.7438 3.13033C75.2812 3.09173 74.8157 3.14566 74.3741 3.289C73.9324 3.43235 73.5235 3.66226 73.1709 3.96544C72.8183 4.26862 72.529 4.63904 72.3198 5.05527C72.0987 5.46018 71.9739 5.91106 71.9552 6.37255C71.4275 6.03682 70.7968 5.90396 70.1792 5.99844C69.5617 6.09293 68.9989 6.40838 68.5946 6.88672C68.2051 7.38222 67.9755 7.98531 67.9362 8.61543C67.897 9.24555 68.05 9.87271 68.3749 10.4131C68.6895 10.952 69.1233 11.4109 69.6426 11.7545C70.1619 12.0981 70.7529 12.3172 71.3699 12.3947C72.6025 12.5477 73.8521 12.3171 74.9501 11.7338C76.069 11.1506 77.0816 10.3813 77.945 9.45872"
        fill="#484848"
      />
      <path
        d="M148.402 65.0235H107.629C106.453 64.9913 105.313 65.4279 104.457 66.238C103.601 67.048 103.099 68.1655 103.061 69.3462V88.7983C103.076 89.3843 103.206 89.9616 103.444 90.4968C103.682 91.0321 104.023 91.5146 104.448 91.9165C104.873 92.3185 105.372 92.6319 105.918 92.8386C106.464 93.0453 107.046 93.1413 107.629 93.121H112.279V99.4424C112.29 99.6233 112.369 99.7934 112.5 99.9176C112.631 100.042 112.805 100.111 112.986 100.11C113.165 100.098 113.337 100.036 113.484 99.9315L120.667 93.1654H148.362C149.537 93.1976 150.677 92.7609 151.533 91.9508C152.389 91.1408 152.89 90.0233 152.928 88.8427V69.3451C152.914 68.7625 152.786 68.1885 152.551 67.6558C152.315 67.1231 151.978 66.6423 151.558 66.2408C151.137 65.8394 150.642 65.5252 150.101 65.3163C149.559 65.1074 148.982 65.0079 148.402 65.0235Z"
        fill="white"
        stroke="#9378F8"
        strokeWidth="3.9524"
      />
      <path
        d="M148.402 65.0235H107.629C106.453 64.9913 105.313 65.4279 104.457 66.238C103.601 67.048 103.099 68.1655 103.061 69.3462V88.7983C103.076 89.3843 103.206 89.9616 103.444 90.4968C103.682 91.0321 104.023 91.5146 104.448 91.9165C104.873 92.3185 105.372 92.6319 105.918 92.8386C106.464 93.0453 107.046 93.1413 107.629 93.121H112.279V99.4424C112.29 99.6233 112.369 99.7934 112.5 99.9176C112.631 100.042 112.805 100.111 112.986 100.11C113.165 100.098 113.337 100.036 113.484 99.9315L120.667 93.1654H148.362C149.537 93.1976 150.677 92.7609 151.533 91.9508C152.389 91.1408 152.89 90.0233 152.928 88.8427V69.3451C152.914 68.7625 152.786 68.1885 152.551 67.6558C152.315 67.1231 151.978 66.6423 151.558 66.2408C151.137 65.8394 150.642 65.5252 150.101 65.3163C149.559 65.1074 148.982 65.0079 148.402 65.0235Z"
        fill="#9378F8"
      />
      <path
        d="M119.195 81.8809C120.618 81.8809 121.772 80.7218 121.772 79.2919C121.772 77.862 120.618 76.7028 119.195 76.7028C117.772 76.7028 116.618 77.862 116.618 79.2919C116.618 80.7218 117.772 81.8809 119.195 81.8809Z"
        fill="white"
      />
      <path
        d="M127.839 81.8809C129.262 81.8809 130.416 80.7218 130.416 79.2919C130.416 77.862 129.262 76.7028 127.839 76.7028C126.416 76.7028 125.262 77.862 125.262 79.2919C125.262 80.7218 126.416 81.8809 127.839 81.8809Z"
        fill="white"
      />
      <path
        d="M136.482 81.8809C137.905 81.8809 139.059 80.7218 139.059 79.2919C139.059 77.862 137.905 76.7028 136.482 76.7028C135.058 76.7028 133.905 77.862 133.905 79.2919C133.905 80.7218 135.058 81.8809 136.482 81.8809Z"
        fill="white"
      />
    </svg>
  );
};
