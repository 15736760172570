import axios from 'axios';

import { URL_HOST } from '@/constants/environment';
interface ScrappingUrlRequestOptions {
  method: 'POST';
  url: string;
  headers: { 'Content-Type': 'application/json' };
  data: {
    business_url: string;
  };
}

export const scrappingUrl = async (url: string) => {
  const options: ScrappingUrlRequestOptions = {
    method: 'POST',
    url: `${URL_HOST}/scrapping`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      business_url: url,
    },
  };

  const response = await axios.request(options);

  // TODO: remove this log after demo
  // eslint-disable-next-line no-console
  console.log(response.data);
  return response.data as string;
};
